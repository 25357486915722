<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-2 pt-0">
      <div class="document-header">
        <div class="block-divider ml-0 mt-0">
          Шапка документу
        </div>
        <div class="document-header-row px-0 pt-0 pb-2">
          <div style="flex: 0 0 180px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true" required/>
          </div>
          <div style="flex: 0 0 144px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"/>
          </div>
          <div style="flex: 0 0 310px; margin-right: 16px">
            <select_input
                v-model="person_id"
                :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                :computed-getter="'get_personal_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Працівник"
                :show-select-on-focus="false"
                :action-filter="'@field=type@;@start-with=S@full_time'"
                :required="true"
                :select-button-object="{
                          name: 'PERSONAL_ELEMENTS',
                          group: '',
                          hierarchy: false,
                          only_groups: false,
                          title: 'Список працівників',
                          element_title: 'Працівник',
                          action_filter: '@field=person_type@;@start-with=S@full_time',
                          show: true
                        }"
                @selectChange="onPersonChange"
            />
          </div>
        </div>
      </div>
      <div class="document-body pt-0" style="padding: 5px">
        <div class="block-divider ml-0 mt-0">
          Табличні дані
        </div>
        <div>
          <div>
            <div class="document-body-row pt-1">
              <div style="flex: 0 0 596px; margin-right: 16px">
                <select_input
                    v-model="privilege_type"
                    :import-getter="{ name: 'social_privilege_select' }"
                    :input-value-as-value="false"
                    :show-search-on-focus="true"
                    :item-search="'text'"
                    :nudge-width="'100%'"
                    label="Пільга: "
                />
              </div>
              <div style="flex: 0 0 60px; margin-right: 16px">
                <string_input :value="privilege_percent" label="Процент" disabled/>
              </div>
            </div>
            <div class="document-body-row pt-3">
              <div style="flex: 0 0 328px; margin-right: 16px">
                <date_input label="Дата початку" v-model="date_start" required/>
              </div>
              <div style="flex: 0 0 328px; margin-right: 16px">
                <date_input label="Дата закінчення" v-model="date_end" :required="false"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <template>
        <v-spacer></v-spacer>
        <v-btn depressed color="grey lighten-3" v-if="!conducted" :disabled="isNew"
               :loading="getModalLoading"
               @click="doConducted(true)">
          <v-icon color="success">mdi-file-check-outline</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="conducted" :disabled="isNew"
               :loading="getModalLoading"
               @click="doConducted(false)">
          <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
        </v-btn>
        <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
               class="button-accept">
          <v-icon left>mdi-content-save</v-icon>
          Зберегти
        </v-btn>
        <v-btn depressed text tile color="grey" @click="closeModal">
          <v-icon left>mdi-close</v-icon>
          Відмінити
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
  UPDATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
  REMOVE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
  CONDUCTED_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import socialPrivilegeAPI from "@/utils/axios/accounting/documents/social_privilege";
import {format_date_from_python, getDateFromString} from "@/utils/icons";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";

const modalDeleteId = 'social_privilege_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Social_Privilege.vue",
  mixins: [ModalAccountingComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    date_input: () => import("@/components/accounting/input/document_date_input")
  },
  data() {
    return {
      person_id: this.item.person_id || null,
      person_name: this.item.person_name || "",
      date_start: this.item.date_start || null,
      date_end: this.item.date_end || null,
      privilege_type: this.item.privilege_type || null,
    }
  },
  methods: {
    checkDocument(show = false, table = false) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'person_id': {id: 'person_id', text: 'Не заповнений працівник'},
        'privilege_type': {id: 'privilege_type', text: 'Не заповнена пільга'},
        'date_start': {id: 'date_start', text: 'Не заповнена дата початку пільги'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      if (this.date && this.hire_date) {
        if (getDateFromString(this.date) < getDateFromString(this.hire_date)) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: 'Дата документа має бути більшою за дату прийняття на роботу'}
          )
        }
      }

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        console.log('check table')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    onPersonChange(payload) {
      this.person_name = (payload || {}).text || (payload || {}).full_name || ""
    },
    validateForm(only_bool = false) {
      const required_items = {
        'date': 'Дата',
        'person_id': 'Працівник',
        'date_start': 'Дата початку',
        'privilege_type': 'Пільга',
      }

      let form_valid = true
      let errors = []

      Object.keys(required_items).forEach(key => {
        if (!this[key]) {
          form_valid = false
          if (!only_bool) {
            errors.push(required_items[key])
          }
        }
      })

      if (only_bool) return form_valid
      let text = 'Виправте помилки: \n' + errors.join(', ') + ' - мають бути заповнені. \n'
      return {
        valid: form_valid,
        text: text
      }

    },

    clearPersonData(payload) {
      this.date = payload.date || null
      this.number = payload.number || null
      this.person_id = payload.person_id || null
      this.person_name = payload.person_name || ""
      this.date_start = payload.date_start || null
      this.date_end = payload.date_end || null
      this.privilege_type = payload.privilege_type || null
      this.itemId = payload.id || null
    },
    fetch_data_by_id() {
      if (this.isNew) return

      socialPrivilegeAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.conducted = data.conducted || false
            this.date = data.date || null
            this.number = data.number || null
            this.person_id = data.person_id || null
            this.person_name = data.person_name || ''
            this.date_start = data.date_start || null
            this.date_end = data.date_end || null
            this.privilege_type = data.privilege_type || null
          })
    },
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, { text, color })
            }

          })
    },

    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документу заява на податкову пільгу № ${this.number} від ${format_date_from_python(this.date)}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        number: this.number,
        person_id: this.person_id,
        date_start: this.date_start,
        date_end: this.date_end,
        privilege_type: this.privilege_type,
      }
      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.checkDocument()
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_SOCIAL_PRIVILEGE_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    }
  },
  computed: {
    privilege_percent() {
      if (this.privilege_type) {
        const position = this.privilege_type.lastIndexOf('_')
        return this.privilege_type.substr(position+1)
      } else {
        return '0'
      }
    }
  }
}
</script>
